import * as React from 'react';

import { Typography, Box, Button, Divider, Container } from '@mui/material';
import Link from 'next/link';

const FooterLG = () => {
  return (
    <Box
      py="46px"
      px="70px"
      sx={{
        backgroundColor: `primary.main`,
        color: 'white',
      }}
    >
      <Container>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography
              component="p"
              className="font-Avenir-Regular"
              fontSize="20px"
            >
              Vill du komma i kontakt med oss?
            </Typography>

            <Link href="mailto:info@pm.se" passHref legacyBehavior>
              <Typography
                component="a"
                mt="20px"
                className="font-Lato-Light"
                fontSize="18px"
                lineHeight="17px"
                sx={{
                  cursor: 'pointer',
                  textDecoration: 'none',
                  color: 'white',
                }}
              >
                info@pm.se
              </Typography>
            </Link>
            <Link href="tel:+46107626210" passHref legacyBehavior>
              <Typography
                component="a"
                mt="25px"
                className="font-Lato-Light"
                fontSize="18px"
                lineHeight="17px"
                sx={{
                  cursor: 'pointer',
                  textDecoration: 'none',
                  color: 'white',
                }}
              >
                Ring 010-762 62 10
              </Typography>
            </Link>
          </Box>
          <Box
            sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'column', mr: '30px' }}>
              <Typography
                component="p"
                className="font-Avenir-Regular"
                fontSize="20px"
              >
                Vill du veta vad din bostad är värd?
              </Typography>

              <Typography
                component="p"
                mt="11px"
                className="font-Lato-Light"
                fontSize="18px"
                lineHeight="17px"
              >
                Kontakta oss för en fri värdering
              </Typography>
            </Box>
            <Link href="/vardering" passHref style={{ textDecoration: 'none' }}>

              <Button
                variant="contained"
                color="warning"
                sx={{
                  textTransform: 'none',
                  width: '220px',
                  height: '46px',
                  backgroundColor: '#FBEBAA',
                  borderRadius: '0px',
                }}
              >
                <Typography
                  component="p"
                  className="font-Lato-Regular"
                  fontSize="17px"
                  lineHeight="18px"
                  color="black"
                >
                  Fri värdering
                </Typography>
              </Button>

            </Link>
          </Box>
        </Box>

        <Divider
          sx={{
            mt: '34px',
            backgroundColor: 'white',
          }}
        />
        <Box sx={{ display: 'flex' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography
              component="p"
              mt="25px"
              className="font-Lato-Bold"
              fontSize="20px"
              lineHeight="16px"
            >
              Kontor
            </Typography>
            <Typography
              component="p"
              mt="27px"
              className="font-Lato-Light"
              fontSize="18px"
              lineHeight="18px"
            >
                Industrigatan 4A
            </Typography>
            <Typography
              component="p"
              mt="25px"
              className="font-Lato-Light"
              fontSize="18px"
              lineHeight="18px"
            >
                112 46 Stockholm
            </Typography>
            <Box display="flex" mt="35px">
              <Link href="https://www.facebook.com/Privatmaklaren" passHref>

                <Box
                  component="img"
                  src={`${process.env.NEXT_PUBLIC_URL}/images/footerFB.svg`}
                  mr="10px"
                  sx={{ cursor: 'pointer' }}
                />

              </Link>
              <Link href="https://www.instagram.com/privatmaklaren" passHref>

                <Box
                  component="img"
                  src={`${process.env.NEXT_PUBLIC_URL}/images/footer@.svg`}
                  sx={{ cursor: 'pointer' }}
                />

              </Link>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', ml: 'auto' }}>
            <Typography
              component="p"
              mt="25px"
              className="font-Lato-Bold"
              fontSize="20px"
              lineHeight="16px"
              sx={{ textTransform: 'uppercase' }}
            >
              Sälja
            </Typography>
            <Link href="/salja#maklare" passHref legacyBehavior>
              <Typography
                component="a"
                mt="27px"
                className="font-Lato-Light"
                fontSize="17px"
                lineHeight="18px"
                sx={{
                  cursor: 'pointer',
                  color: 'white',
                  textDecoration: 'none',
                }}
              >
                Mäklare
              </Typography>
            </Link>
            {/*<Link href="/salja#annons" passHref>
              <Typography
                component="a"
                mt="20px"
                className="font-Lato-Light"
                fontSize="17px"
                lineHeight="18px"
                sx={{
                  cursor: 'pointer',
                  color: 'white',
                  textDecoration: 'none',
                }}
              >
                Annons
              </Typography>
            </Link>*/}
            <Link href="/salja#kontrakt" passHref legacyBehavior>
              <Typography
                component="a"
                mt="20px"
                className="font-Lato-Light"
                fontSize="17px"
                lineHeight="18px"
                sx={{
                  cursor: 'pointer',
                  color: 'white',
                  textDecoration: 'none',
                }}
              >
                Kontrakt
              </Typography>
            </Link>
            <Link href="/vardering" passHref legacyBehavior>
              <Typography
                component="a"
                mt="20px"
                className="font-Lato-Light"
                fontSize="17px"
                lineHeight="18px"
                sx={{
                  cursor: 'pointer',
                  color: 'white',
                  textDecoration: 'none',
                }}
              >
                Fri värdering
              </Typography>
            </Link>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', ml: '60px' }}>
            <Typography
              component="p"
              mt="25px"
              className="font-Lato-Bold"
              fontSize="20px"
              lineHeight="16px"
              sx={{ textTransform: 'uppercase' }}
            >
              Köpa
            </Typography>
            <Link href="/kopa" passHref legacyBehavior>
              <Typography
                component="a"
                mt="27px"
                className="font-Lato-Light"
                fontSize="17px"
                lineHeight="18px"
                sx={{
                  cursor: 'pointer',
                  color: 'white',
                  textDecoration: 'none',
                }}
              >
                Köpa bostad
              </Typography>
            </Link>
            <Link href="/till-salu" passHref legacyBehavior>
              <Typography
                component="a"
                mt="20px"
                className="font-Lato-Light"
                fontSize="17px"
                lineHeight="18px"
                sx={{
                  cursor: 'pointer',
                  color: 'white',
                  textDecoration: 'none',
                }}
              >
                Till salu
              </Typography>
            </Link>
            <Link href="/kopa/hur-man-lagger-bud" passHref legacyBehavior>
              <Typography
                component="a"
                mt="20px"
                className="font-Lato-Light"
                fontSize="17px"
                lineHeight="18px"
                sx={{
                  cursor: 'pointer',
                  color: 'white',
                  textDecoration: 'none',
                }}
              >
                Budgivning
              </Typography>
            </Link>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', ml: '60px' }}>
            <Typography
              component="a"
              mt="25px"
              className="font-Lato-Bold"
              fontSize="20px"
              lineHeight="16px"
              sx={{
                textTransform: 'uppercase',
                color: 'white',
                textDecoration: 'none',
              }}
            >
              Guider
            </Typography>

            <Link href="/guide/salja-bostad-privat" passHref legacyBehavior>
              <Typography
                component="a"
                mt="27px"
                className="font-Lato-Light"
                fontSize="17px"
                lineHeight="18px"
                sx={{
                  cursor: 'pointer',
                  color: 'white',
                  textDecoration: 'none',
                }}
              >
                Sälja bostad privat
              </Typography>
            </Link>
            <Link href="/guide/fotografering-checklista" passHref legacyBehavior>
              <Typography
                component="a"
                mt="20px"
                className="font-Lato-Light"
                fontSize="17px"
                lineHeight="18px"
                sx={{
                  cursor: 'pointer',
                  color: 'white',
                  textDecoration: 'none',
                }}
              >
                Checklista foto bostad
              </Typography>
            </Link>
            <Link href="/guide/checklista-flyttstadning" passHref legacyBehavior>
              <Typography
                component="a"
                mt="20px"
                className="font-Lato-Light"
                fontSize="17px"
                lineHeight="18px"
                sx={{
                  cursor: 'pointer',
                  color: 'white',
                  textDecoration: 'none',
                }}
              >
                Checklista städ
              </Typography>
            </Link>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', ml: '60px' }}>
            <Typography
              component="p"
              mt="25px"
              className="font-Lato-Bold"
              fontSize="20px"
              lineHeight="16px"
              sx={{ textTransform: 'uppercase' }}
            >
              Om oss
            </Typography>

            <Link href="/om-oss" passHref legacyBehavior>
              <Typography
                component="a"
                mt="27px"
                className="font-Lato-Light"
                fontSize="17px"
                lineHeight="18px"
                sx={{
                  cursor: 'pointer',
                  color: 'white',
                  textDecoration: 'none',
                }}
              >
                Om Privatmäklaren
              </Typography>
            </Link>
            <Link href="/om-oss#medarbetare" passHref legacyBehavior>
              <Typography
                component="a"
                mt="20px"
                className="font-Lato-Light"
                fontSize="17px"
                lineHeight="18px"
                sx={{
                  cursor: 'pointer',
                  color: 'white',
                  textDecoration: 'none',
                }}
              >
                Medarbetare
              </Typography>
            </Link>
            <Link href="/omdomen" passHref legacyBehavior>
              <Typography
                component="a"
                mt="20px"
                className="font-Lato-Light"
                fontSize="17px"
                lineHeight="18px"
                sx={{
                  cursor: 'pointer',
                  color: 'white',
                  textDecoration: 'none',
                }}
              >
                Referenser
              </Typography>
            </Link>
            <Link href="/vanliga-fragor" passHref legacyBehavior>
              <Typography
                component="a"
                mt="20px"
                className="font-Lato-Light"
                fontSize="17px"
                lineHeight="18px"
                sx={{
                  cursor: 'pointer',
                  color: 'white',
                  textDecoration: 'none',
                }}
              >
                Vanliga frågor
              </Typography>
            </Link>
            <Link href="https://blogg.privatmaklaren.se" passHref legacyBehavior>
              <Typography
                component="a"
                mt="20px"
                className="font-Lato-Light"
                fontSize="17px"
                lineHeight="18px"
                sx={{
                  cursor: 'pointer',
                  color: 'white',
                  textDecoration: 'none',
                }}
              >
                Blogg
              </Typography>
            </Link>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mt: '120px',
          }}
        >
          <Link href="/" passHref>

            <Box
              component="img"
              src={`${process.env.NEXT_PUBLIC_URL}/images/LogoImage.svg`}
              mt="23px"
              width="140px"
              sx={{ cursor: 'pointer' }}
            />

          </Link>
          <Typography
            component="p"
            mt="15px"
            className="font-Avenir-Regular"
            fontSize="18px"
            lineHeight="18px"
            sx={{ textTransform: 'uppercase' }}
          >
            {`${new Date().getFullYear()} copyright Privatmäklaren`}
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};
export default FooterLG;
