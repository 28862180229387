import React from 'react';
// import Box from '@mui/material/Box';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
// import CloseIcon from '@mui/icons-material/Close';
import { SvgIcon, Typography } from '@mui/material';
// import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import { connect } from 'react-redux';
import Link from 'next/link';

interface CustomerMessageProps {
  bgColor?: string;
  entries: any[];
  text?: string;
}

const CustomerMessage = (props: CustomerMessageProps) => {
  const { bgColor, entries } = props;
  const [message, setMessage] = React.useState('');
  const [url, setUrl] = React.useState('');
  React.useEffect(() => {
    const res = entries.find(
      (entry) => entry.sys.contentType.sys.id === 'customerMessage'
    );

    if (!res) return;
    setMessage(res.fields.message);
    setUrl(res.fields.url);
  }, []);

  if (message === '' && url === '') return <></>;
  const content = (
    <React.Fragment>
      <Typography
        component="p"
        className="font-Lato-Regular"
        sx={{
          fontSize: { xs: '10px', md: '14px' },
          paddingBottom: '1px',
        }}
      >
        {message}
      </Typography>

      <SvgIcon component={ChevronRightIcon} fontSize="small" />
    </React.Fragment>
  );
  return (
    <Link href={url} legacyBehavior>
      <Box
        component="a"
        sx={{
          cursor: 'pointer',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: { lg: '32px', xs: '26px' },
          zIndex: 20,
          backgroundColor: bgColor === 'gray' ? '#EEE' : 'transparent',
          textDecoration: 'none',
        }}
      >
        {content}
      </Box>
    </Link>
  );
};

const mapStateToProps = (state) => {
  return {
    entries: state.main.entries,
  };
};

export default connect(mapStateToProps, null)(CustomerMessage);
