const createSlug = (word: string) => {
  if (!word) {
    return '';
  }

  return word
    .toLowerCase()
    .replaceAll('å', 'a')
    .replaceAll('ä', 'a')
    .replaceAll('ö', 'o')
    .replaceAll('_', '-')
    .replaceAll(' ', '-');
};

export default createSlug;
