import React from 'react';
import FooterXS from './FooterXS';
import FooterLG from './FooterLG';
import useWidth from '../../../utils/useWidth';

const ResponsiveComponent = () => {
  const width = useWidth();
  return <>{width === 'lg' || width === 'xl' ? <FooterLG /> : <FooterXS />}</>;
};
const Footer = () => {
  return (
    <>
      <ResponsiveComponent />
    </>
  );
};

export default Footer;
