import Head from "next/head";
import {Box, Container} from "@mui/material";
import CustomerMessage from "../../../contentful/content-types/CustomerMessage";
import Navbar from "../../../contentful/content-types/Navbar";
import Link from "next/link";
import Footer from "../../../contentful/content-types/Footer";
import React from "react";
import Image from 'next/image';
import notFound from '../../../../public/images/not-found.svg';

const NotFound = () => {
    return (
        <>
            <Head>
                <title>Sidan kan inte hittas</title>
            </Head>
            <Box
                sx={{ width: '100%', position: 'fixed', zIndex: '20', top: '0px' }}
            >
                <CustomerMessage bgColor="gray" />
                <Navbar />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                marginTop: "130px"
              }}
            >
              <Image width="600" src={notFound} alt="Sidan kunde inte hittas"></Image>
            </Box>
            <Container sx={{ pb: '40px', textAlign: 'center' }}>
                <Link href="/till-salu">Se våra objekt till salu</Link>
            </Container>

            <Footer />
        </>
    );
}

export default NotFound;
