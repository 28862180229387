import * as React from 'react';

import { Typography, Box, Button, Divider } from '@mui/material';
import Link from 'next/link';

const FooterXS = () => {
  return (
    <Box
      py="24px"
      px="16px"
      sx={{
        backgroundColor: `primary.main`,
        color: 'white',
      }}
    >
      <Typography component="p" className="font-Avenir-Regular" fontSize="18px">
        Vill du veta vad din bostad är värd?
      </Typography>
      <Typography
        component="p"
        mt="11px"
        className="font-Lato-Regular"
        fontSize="14px"
        lineHeight="17px"
      >
        Kontakta oss för en fri värdering
      </Typography>
      <Link href="/vardering" passHref legacyBehavior>
        <Button
          variant="contained"
          color="warning"
          sx={{
            mt: '30px',
            textTransform: 'none',
            width: '186px',
            height: '46px',
            backgroundColor: '#FBEBAA',
            borderRadius: '0px',
          }}
        >
          <Typography
            component="p"
            className="font-Lato-Regular"
            fontSize="16px"
            lineHeight="18px"
            color="black"
          >
            Fri värdering
          </Typography>
        </Button>
      </Link>
      <Divider
        sx={{
          mt: '34px',
          backgroundColor: 'white',
        }}
      />
      <Typography
        component="p"
        mt="28px"
        className="font-Lato-Bold"
        fontSize="16px"
        lineHeight="16px"
        sx={{ textTransform: 'uppercase' }}
      >
        Sälja
      </Typography>
      <Link href="/salja#maklare" passHref legacyBehavior>
        <Typography
          component="a"
          mt="8px"
          className="font-Lato-Light"
          fontSize="16px"
          lineHeight="18px"
          sx={{ cursor: 'pointer', textDecoration: 'none', color: 'white' }}
        >
          Mäklare
        </Typography>
      </Link>
      <br></br>
      {/*<Link href="/salja#annons" passHref>
        <Typography
          component="a"
          mt="8px"
          className="font-Lato-Light"
          fontSize="16px"
          lineHeight="18px"
          sx={{ cursor: 'pointer', textDecoration: 'none', color: 'white' }}
        >
          Annons
        </Typography>
      </Link>
      <br></br>*/}

      <Link href="/salja#kontrakt" passHref legacyBehavior>
        <Typography
          component="a"
          mt="8px"
          className="font-Lato-Light"
          fontSize="16px"
          lineHeight="18px"
          sx={{ cursor: 'pointer', textDecoration: 'none', color: 'white' }}
        >
          Kontrakt
        </Typography>
      </Link>
      <br></br>
      <Link href="/vardering" passHref legacyBehavior>
        <Typography
          component="a"
          mt="8px"
          className="font-Lato-Light"
          fontSize="16px"
          lineHeight="18px"
          sx={{ cursor: 'pointer', textDecoration: 'none', color: 'white' }}
        >
          Fri värdering
        </Typography>
      </Link>
      <Typography
        component="p"
        mt="28px"
        className="font-Lato-Bold"
        fontSize="16px"
        lineHeight="16px"
        sx={{ textTransform: 'uppercase' }}
      >
        Köpa
      </Typography>

      <Link href="/kopa" passHref legacyBehavior>
        <Typography
          component="a"
          mt="8px"
          className="font-Lato-Light"
          fontSize="16px"
          lineHeight="18px"
          sx={{ cursor: 'pointer', textDecoration: 'none', color: 'white' }}
        >
          Köpa bostad
        </Typography>
      </Link>

      <br></br>
      <Link href="/till-salu" passHref legacyBehavior>
        <Typography
          component="a"
          mt="8px"
          className="font-Lato-Light"
          fontSize="16px"
          lineHeight="18px"
          sx={{ cursor: 'pointer', textDecoration: 'none', color: 'white' }}
        >
          Till salu
        </Typography>
      </Link>

      <br></br>
      <Link href="/kopa/hur-man-lagger-bud" passHref legacyBehavior>
        <Typography
          component="a"
          mt="8px"
          className="font-Lato-Light"
          fontSize="16px"
          lineHeight="18px"
          sx={{ cursor: 'pointer', textDecoration: 'none', color: 'white' }}
        >
          Budgivning
        </Typography>
      </Link>

      <Typography
        component="p"
        mt="28px"
        className="font-Lato-Bold"
        fontSize="16px"
        lineHeight="16px"
      >
        GUIDER
      </Typography>

      <Link href="/guide/salja-bostad-privat" passHref legacyBehavior>
        <Typography
          component="a"
          mt="8px"
          className="font-Lato-Light"
          fontSize="16px"
          lineHeight="18px"
          sx={{ cursor: 'pointer', textDecoration: 'none', color: 'white' }}
        >
          Sälja bostad privat
        </Typography>
      </Link>
      <br></br>
      <Link href="/guide/fotografering-checklista" passHref legacyBehavior>
        <Typography
          component="a"
          mt="8px"
          className="font-Lato-Light"
          fontSize="16px"
          lineHeight="18px"
          sx={{ cursor: 'pointer', textDecoration: 'none', color: 'white' }}
        >
          Checklista foto bostad
        </Typography>
      </Link>
      <br></br>
      <Link href="/guide/checklista-flyttstadning" passHref legacyBehavior>
        <Typography
          component="a"
          mt="8px"
          className="font-Lato-Light"
          fontSize="16px"
          lineHeight="18px"
          sx={{ cursor: 'pointer', textDecoration: 'none', color: 'white' }}
        >
          Checklista städ
        </Typography>
      </Link>

      <Typography
        component="p"
        mt="22px"
        className="font-Lato-Bold"
        fontSize="16px"
        lineHeight="16px"
      >
        OM OSS
      </Typography>
      <Link href="/om-oss" passHref legacyBehavior>
        <Typography
          component="a"
          mt="8px"
          className="font-Lato-Light"
          fontSize="16px"
          lineHeight="18px"
          sx={{ cursor: 'pointer', textDecoration: 'none', color: 'white' }}
        >
          Om Privatmäklaren
        </Typography>
      </Link>
      <br></br>
      <Link href="/om-oss#medarbetare" passHref legacyBehavior>
        <Typography
          component="a"
          mt="8px"
          className="font-Lato-Light"
          fontSize="16px"
          lineHeight="18px"
          sx={{ cursor: 'pointer', textDecoration: 'none', color: 'white' }}
        >
          Medarbetare
        </Typography>
      </Link>
      <br></br>
      <Link href="/omdomen" passHref legacyBehavior>
        <Typography
          component="a"
          mt="8px"
          className="font-Lato-Light"
          fontSize="16px"
          lineHeight="18px"
          sx={{ cursor: 'pointer', textDecoration: 'none', color: 'white' }}
        >
          Referenser
        </Typography>
      </Link>
      <br></br>
      <Link href="/vanliga-fragor" passHref legacyBehavior>
        <Typography
          component="a"
          mt="8px"
          className="font-Lato-Light"
          fontSize="16px"
          lineHeight="18px"
          sx={{ cursor: 'pointer', textDecoration: 'none', color: 'white' }}
        >
          Vanliga frågor
        </Typography>
      </Link>
      <br></br>
      <Link href="https://blogg.privatmaklaren.se" passHref legacyBehavior>
        <Typography
          component="a"
          mt="8px"
          className="font-Lato-Light"
          fontSize="16px"
          lineHeight="18px"
          sx={{ cursor: 'pointer', textDecoration: 'none', color: 'white' }}
        >
          Blogg
        </Typography>
      </Link>

      <Typography
        component="p"
        mt="22px"
        className="font-Lato-Bold"
        fontSize="16px"
        lineHeight="16px"
      >
        KONTOR
      </Typography>
      <Typography
        component="p"
        mt="8px"
        className="font-Lato-Light"
        fontSize="16px"
        lineHeight="18px"
      >
        Industrigatan 4A
      </Typography>
      <Typography
        component="p"
        mt="8px"
        className="font-Lato-Light"
        fontSize="16px"
        lineHeight="18px"
      >
        112 46 Stockholm
      </Typography>
      <Box display="flex" mt="35px">
        <Link href="https://www.facebook.com/Privatmaklaren" passHref>

          <Box
            component="img"
            src={`${process.env.NEXT_PUBLIC_URL}/images/footerFB.svg`}
            mr="10px"
            sx={{ cursor: 'pointer' }}
          />

        </Link>
        <Link href="https://www.instagram.com/privatmaklaren" passHref>

          <Box
            component="img"
            src={`${process.env.NEXT_PUBLIC_URL}/images/footer@.svg`}
            sx={{ cursor: 'pointer' }}
          />

        </Link>
      </Box>
      <Link href="/" passHref>

        <Box
          component="img"
          src={`${process.env.NEXT_PUBLIC_URL}/images/LogoImage.svg`}
          mt="23px"
          width="140px"
          sx={{ cursor: 'pointer' }}
        />

      </Link>

      <Typography
        component="p"
        mt="15px"
        className="font-Avenir-Regular"
        fontSize="12px"
        lineHeight="18px"
      >
        {`${new Date().getFullYear()} copyright Privatmäklaren`}
      </Typography>
    </Box>
  );
};
export default FooterXS;
